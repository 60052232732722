<template>
    <div class="message-text">
        <!-- <div class="c-black" v-if="isFaq" v-html="$translate('ACTION_FAQ_OPEN_CHAT_NORMAL_BODY')" /> -->
        <!-- <div class="c-black" v-else v-html="$translate('ACTION_OPEN_CHAT_NORMAL_BODY')" /> -->
        <div v-if="$isTester()" class="open-chat">
            <div class="noti-tester">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span>퍼스널 매니저가 배정되었어요!</span>
            </div>
            <div class="noti-body">안녕하세요, 퍼스널 매니저입니다! 반갑습니다 😊<br /></div>
        </div>
        <div v-else class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span v-if="planDetail.name.includes('Love Fit')">Love Fit 멤버십이 <br />시작됐어요!</span>
                <span v-else-if="planDetail.name.includes('Marry Fit Plus')"
                    >Marry Fit + 멤버십이 <br />시작됐어요!</span
                >
                <span v-else>Marry Fit 멤버십이 <br />시작됐어요!</span>
            </div>
            <div class="noti-body" v-if="planDetail.name.includes('Love Fit')">
                안녕하세요, {{ me.name }}님을 담당할 {{ agentName }}입니다! 반갑습니다 😊<br />
                매칭 절차 간단하게 안내드릴게요.<br />
                1. 간단한 매칭 선호 사항을 입력해주세요.<br />
                2. 매주 1회 정기 프로필을 전달드려요. 어떤 요일에 전달 드릴지는 매니저가 곧 안내드릴거에요.<br />
                (매니저 재량에 따라 추가로 프로필이 전달될 수 있어요.)<br />
                3. 매니저가 1~2 영업일 이내에 프로필을 준비해서 보내드릴 예정이니 조금만 기다려주세요 😊<br />
            </div>
            <div class="noti-body" v-else>
                안녕하세요, {{ me.name }}님을 담당할 {{ agentName }}입니다! 반갑습니다 😊<br />
            </div>

            <div v-if="planDetail.name.includes('Love Fit')" class="noti-blue-box">
                <div>기타 문의사항은 고객센터에 남겨주시면 친절히 도와드릴게요.</div>
            </div>
            <div class="noti-body">
                <BottomButton
                    @click="onClickMatchPrefer"
                    label="매칭 선호사항 입력하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'ActionOpenChatMembership',
    props: ['message', 'item'],
    data: () => ({
        stats: [],
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        isFaq() {
            const chatUser = this.chat.user

            return chatUser.id === 2
        },
        me() {
            return this.$store.getters.me
        },
        agentId() {
            return this.$store.getters.chat.agent_id
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        dontShow() {
            // if (this.$isTester() || (this.$store.getters.device && this.$store.getters.device.os === 'ios')) {
            if (this.$isTester()) {
                return true
            } else {
                return false
            }
        },
        planDetail() {
            if (this.item) {
                return getPlanNameById(this.item.product_id)
            } else {
                return null
            }
        },
        agentName() {
            if (this.planDetail.code.includes('mfplus_3month')) {
                if (this.agentId === 17) {
                    return '고혜린 프로'
                }
                return '최성만 프로'
            } else if (this.planDetail.code.includes('mf_3month')) {
                return '이지연 프로'
            } else {
                return '퍼스널 매칭 매니저'
            }
        },
    },
    methods: {
        onClickMatchPrefer() {
            this.$stackRouter.push({
                name: 'EditStylePage',
            })
        },
    },
}
</script>
<style scoped lang="scss">
.open-chat {
    padding: 9px 4px;
    .noti-tester {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        align-items: center;
    }
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
